import React from 'react';
import PropTypes from 'prop-types';
import { Box, ImageListItem, styled } from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';

const BoxBefore = styled(Box)({
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '14px',
  borderRadius: '3px',
  backgroundColor: '#e7f9f9',
  height: '18px',
  color: '#00b2b2',
  width: '44px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const BoxIng = styled(Box)({
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '14px',
  borderRadius: '3px',
  backgroundColor: '#557cf2',
  height: '18px',
  color: '#fff',
  width: '35px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const BoxAfter = styled(Box)({
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '14px',
  borderRadius: '3px',
  backgroundColor: '#868e96',
  height: '18px',
  color: '#fff',
  width: '27px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const getEventTag = event_yn => {
  if (!event_yn) return null;
  if (event_yn === 'before') return <BoxBefore>진행예정</BoxBefore>;
  if (event_yn === 'ing') return <BoxIng>진행중</BoxIng>;
  return <BoxAfter>종료</BoxAfter>;
};
const getDate = rawDate => {
  return dayjs(new Date(rawDate)).format('YYYY.MM.DD');
};
const EventView = ({ item, onClick, mobile }) => {
  const theme = useTheme();
  const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    marginBottom: '30px',
    [theme.breakpoints.up('desktop')]: {
      height: '185px',
      paddingRight: '22px',
      borderBottom: '1px solid #e6eaed',
      marginBottom: '40px',
    },
  });
  return (
    <ImageListItem
      onClick={() => onClick(item.idx, item.rownum)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box display="flex" flexDirection="column">
        <img
          src={item.image_url}
          loading="lazy"
          alt={item.title}
          style={{
            width: mobile ? '100%' : '302px',
            height: mobile ? 'auto' : '160px',
            borderRadius: '8px',
            objectFit: 'cover',
          }}
        />
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '18px',
                color: '#495057',
              }}
            >
              {item?.category1_title ? item?.category1_title : ''}
              {item?.category2_title ? ` > ${item?.category2_title}` : ''}
            </Box>
            <Box display="flex">
              {item?.important_yn === 'Y' ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '3px',
                    bgcolor: '#00c4c4',
                    width: '27px',
                    height: '18px',
                    color: '#fff',
                    fontWeight: '700',
                    fontSize: '10px',
                    lineHeight: '14px',
                    mr: '4px',
                  }}
                >
                  중요
                </Box>
              ) : null}
              {getEventTag(item?.event_yn)}
            </Box>
          </Box>
          <Box
            sx={{
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#212529',
              mt: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.title}
          </Box>
          {item.summary ? (
            <Box
              sx={{
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '18px',
                color: '#90949c',
                mt: '8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.summary}
            </Box>
          ) : null}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: mobile ? '13px' : '14px',
              lineHeight: '16px',
              color: item?.event_yn === 'ing' ? '#212529' : '#868e96',
              mt: '8px',
            }}
          >
            {item?.start_date ? getDate(item.start_date) : ''}
            {item?.end_date ? ` ~ ${getDate(item.end_date)}` : ''}
          </Box>
        </Container>
      </Box>
    </ImageListItem>
  );
};

EventView.propTypes = {
  item: PropTypes.shape({
    idx: PropTypes.number,
    title: PropTypes.string,
    contents: PropTypes.string,
    rownum: PropTypes.number,
    image_url: PropTypes.string,
    important_yn: PropTypes.string,
    reg_date: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    summary: PropTypes.string,
    category1_title: PropTypes.string,
    category2_title: PropTypes.string,
    event_yn: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

EventView.defaultProps = {
  item: null,
  mobile: false,
};

export default EventView;
