import React, { useState, useContext } from 'react';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { homeContext } from '../../lib/utils';
import SearchInput from './KVBanner/SearchInput';
import PopularWord from '../common/PopularWord';
import RollingBanner from './KVBanner/RollingBanner';

const KVBanner = () => {
  const { banner, popular_word } = useContext(homeContext);
  const [currentStep, setCurrentStep] = useState(1); // 슬라이드 인덱스
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('banner :', banner.kv);

  // currentStep 처음/끝의 인덱스 반영한 현재 슬라이드 번호도출
  const showCurrentStep = currentIdx => {
    let res;
    if (banner.kv?.length >= 1) {
      if (currentIdx === 0) {
        res = banner.kv?.length;
      } else if (currentIdx === banner.kv.length + 1) {
        res = 1;
      } else {
        res = currentIdx;
      }
    }
    return res;
  };

  let backgroundColor = '';

  if (isDeskTop) {
    backgroundColor =
      banner.kv.length >= 1
        ? banner.kv[showCurrentStep(currentStep) - 1]?.bgcolor
        : '#00C4C4';
  }

  return (
    <Container
      sx={{
        transition: '0.5s',
        background: backgroundColor,
        height: isDeskTop ? '440px' : '',
      }}
    >
      {banner === null || popular_word === null ? (
        <Box>Loading</Box>
      ) : (
        <BannerContainer>
          <Box sx={{ display: isDeskTop ? 'block' : 'none' }}>
            <TitleText>
              도전이 쉬워지는 곳,
              <br />
              와디즈 메이커센터입니다.
            </TitleText>
            <SearchInput popularWord={popular_word} />
            <PopularWord
              popularWord={popular_word}
              textColor="#fff"
              bgColor="rgba(255,255,255, .2)"
            />
          </Box>
          <RollingBanner
            kvBanners={banner.kv}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            gaCode="HKB"
          />
        </BannerContainer>
      )}
    </Container>
  );
};

const Container = styled(Box)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const BannerContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  maxWidth: '1280px',
  overflow: 'hidden',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const TitleText = styled(Box)({
  // border: '1px solid red',
  width: '610px',
  fontSize: '48px',
  fontWeight: 700,
  lineHeight: '62px',
  color: '#fff',
  marginBottom: '30px',
});

export default KVBanner;
