/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RollingBannerNaviBar from './RollingBannerNaviBar';

const RollingBanner = ({ kvBanners, currentStep, setCurrentStep, gaCode }) => {
  SwiperCore.use([Navigation, Autoplay]); // swiper 기능 임포트
  const navigationPrevRef = useRef(null); // swiper 커스텀 네비버튼
  const navigationNextRef = useRef(null); // swiper 커스텀 네비버튼
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));

  // console.log('kvBanners :', kvBanners);

  // currentStep 처음/끝의 인덱스 반영한 현재 슬라이드 번호도출
  const showCurrentStep = currentIdx => {
    let res;
    if (kvBanners.length >= 1) {
      if (currentIdx === 0) {
        res = kvBanners.length;
      } else if (currentIdx === kvBanners.length + 1) {
        res = 1;
      } else {
        res = currentIdx;
      }
    }
    return res;
  };

  const moveBanner = direction => {
    window.dataLayer.push({
      event: 'ga.custom.tag',
      GACategory: '홈_키비주얼배너',
      GALabel: direction === 'left' ? '좌' : '우',
      GAAction: '클릭',
    });
  };

  return (
    <Container>
      {kvBanners === null ? (
        <>Loading...</>
      ) : (
        <>
          <RollingBannerBox
            sx={{
              overflow: 'hidden',
              width: isDeskTop ? '580px' : '100%',
              height: isDeskTop ? '320px' : '100%',
              borderRadius: isDeskTop ? '8px' : 0,
            }}
          >
            <Swiper
              className="mySwiper"
              modules={[Navigation, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              loop={kvBanners.length >= 2}
              centeredSlides
              autoHeight={!isDeskTop}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              onSlideChange={swiper => setCurrentStep(swiper.activeIndex)}
            >
              {kvBanners &&
                kvBanners.map((banner, idx) => {
                  return (
                    <SwiperSlide
                      data-ec-list={gaCode}
                      data-promo-id={banner.image_link}
                      data-promo-name={null}
                      data-promo-position={`${gaCode}_${idx}`}
                      key={`kvbanner-${banner.image_url}-${
                        banner.title
                      }-${idx.toString()}`}
                      data-promo-home
                    >
                      <Link
                        href={banner.image_link}
                        target={banner.popup_yn === 'Y' ? '_blank' : '_self'}
                      >
                        <img
                          src={banner.image_url}
                          loading="lazy"
                          alt=""
                          style={{
                            width: '100%',
                            height: isDeskTop ? '320px' : '100%',
                            borderRadius: isDeskTop && '8px',
                            objectFit: 'cover',
                          }}
                        />
                      </Link>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </RollingBannerBox>
          <NaviBarBox
            sx={{
              width: isDeskTop ? '550px' : 'calc(100% - 32px)',
              bottom: isDeskTop ? '15px' : '20px',
            }}
          >
            {kvBanners?.length < 1 ? null : (
              <>
                <BtnBox>
                  <CustomBtn
                    type="button"
                    ref={navigationPrevRef}
                    onClick={() => moveBanner('left')}
                    sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                  >
                    <ArrowBackIosIcon
                      sx={{ color: '#fff', width: '13px', height: '13px' }}
                    />
                  </CustomBtn>
                  <FlexBox sx={{ width: '50px' }}>
                    <Span sx={{ fontWeight: '700' }}>
                      {showCurrentStep(currentStep)}
                    </Span>
                    <Span sx={{ width: '10px' }}>/</Span>
                    <Span>{kvBanners?.length}</Span>
                  </FlexBox>
                  <CustomBtn
                    type="button"
                    ref={navigationNextRef}
                    onClick={() => moveBanner('right')}
                    sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                  >
                    <ArrowForwardIosIcon
                      sx={{ color: '#fff', width: '13px', height: '13px' }}
                    />
                  </CustomBtn>
                </BtnBox>
                <RollingBannerNaviBar
                  currentStep={currentStep}
                  slideLength={kvBanners?.length}
                />
              </>
            )}
          </NaviBarBox>
        </>
      )}
    </Container>
  );
};

RollingBanner.defaultProps = {
  kvBanners: [],
  gaCode: null,
};

RollingBanner.propTypes = {
  kvBanners: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.string,
      image_link: PropTypes.string,
      image_url: PropTypes.string,
      popup_yn: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  gaCode: PropTypes.string,
};

const Container = styled(Box)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});
const RollingBannerBox = styled(Box)({
  width: '100%',
});
const Link = styled('a')({
  display: 'block',
  width: '100%',
  padding: 0,
  margin: 0,
  textDecoration: 'none',
});
const NaviBarBox = styled(Box)({
  position: 'absolute',
  right: '16px',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
const BtnBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  position: 'relative',
  alignItems: 'center',
  marginRight: '10px',
});
const FlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const Span = styled('span')({
  marginTop: '2px',
  width: '15px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  fontSize: '13px',
  fontWeight: 400,
});
const CustomBtn = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  border: 0,
  width: '20px',
  height: '20px',
  borderRadius: '50px',
});

export default RollingBanner;
